import React from "react"
import { handleAuthentication } from "../utils/auth"
import LayoutPage from "../components/layout-page"
import SEO from "../components/seo"
import { navigate } from "gatsby"
import ThankYou from "../components/thank-you"

const Callback = () => {
  handleAuthentication(() => navigate("/"))

  return (
    <LayoutPage>
      <SEO title={"Loading..."}/>
      <ThankYou title={"Please wait"}>
        <p>Thank you for logging in, your browser will redirect you now...</p>
      </ThankYou>
    </LayoutPage>
  )
}

export default Callback
